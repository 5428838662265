/** * Created For: 分页组件 * Author: chenLiYan. * Date: 2019/6/26 0012 13:26.
*/
<template>
  <div v-if='total > 0' class='pagination-container'>
    <el-pagination
      :background='background'
      :current-page.sync='currentPage'
      :page-size.sync='pageSize'
      :page-sizes='pageSizes'
      :layout='layout'
      :total='totalLimit'
      v-bind='$attrs'
      @size-change='handleSizeChange'
      @current-change='handleCurrentChange'
      class='c-pagination'
    >
      <span class='el-pagination__total'>共 {{ total }} 条</span>
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default() {
        return [5, 10, 15, 20, 50, 100]
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: false
    },
    autoScroll: {
      type: Boolean,
      default: false
    },
    // es超出设定值
    eSLimit: {
      type: Number,
      default: 0
    }
  },
  computed: {
    // 防止es超出设定值，分页查询报错
    totalLimit() {
      return this.eSLimit > 0 ? Math.min(this.total, this.eSLimit) : this.total
    },
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.currentPage = 1
      this.$emit('pagination', { page: this.currentPage, limit: val })
    },
    handleCurrentChange(val) {
      window.scrollTo(0, 0)
      this.$emit('pagination', { page: val, limit: this.pageSize })
    }
  }
}
</script>

<style lang='scss' scoped>
.pagination-container {
  background: #fff;
  padding: 30px 15px;
  // margin-top: 30px;
  text-align: center;
  background: none;
}

::v-deep .c-pagination {
  .el-pager li {
    color: #2a2a2a;
    font-size: 14px;

    &.active,
    &:hover {
      color: #3e82f7;
    }
  }

  button:hover {
    color: #3e82f7;
  }

 .el-pager li, button {
    background: none !important;
  }
}
::v-deep .el-pager{
  margin-left: -24px !important;
}
</style>
